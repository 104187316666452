<template>
  <div class="container bank-section">
    <p>Más bancos, más eficacia y agilidad</p>
    <div class="bank-list">
      <img src="/img/banks/INGDirect.jpg" />
      <img src="/img/banks/UCI.jpg" />
      <img src="/img/banks/UnicajaBanco.jpg" />
      <img src="/img/banks/IberCaja.jpg" />
      <img src="/img/banks/EvoBanco.jpg" />
      <div class="my-investor-logo">
        <img src="/img/banks/my investor.png" />
      </div>
      <img src="/img/banks/Sabadell.jpg" />
      <img src="/img/banks/Kutxabank.jpg" />
      <img src="/img/banks/Abanca.jpg" />
      <img src="/img/banks/Bankinter.jpg" />
      <img src="/img/banks/Liberbank.jpg" />
      <img src="/img/banks/CaixaBank.jpg" />
      <img src="/img/banks/Globalcaja.jpg" />
      <img src="/img/banks/Cajasur.jpg" />
      <img src="/img/banks/Targobank.jpg" />
      <img src="/img/banks/Almagro.jpg" />
      <img src="/img/banks/Caser.jpg" />
      <img src="/img/banks/Hipotecas.jpg" />
      <img src="/img/banks/LaboralKutxa.jpg" />
      <img src="/img/banks/Cofidis.jpg" />
      <img src="/img/banks/imaginbank.jpg" />
      
      <img src="/img/banks/Prestalo.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.bank-section {
  padding: 20px 0;
}

.bank-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

img {
  width: 100%;
  height: auto;
  max-width: 80px;
}

.my-investor-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>